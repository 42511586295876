export enum DocumentHostClassesEnum {

    Document = 'document',

    ContentMark = 'document__content-mark',

    Assignment = 'document__assignment',

    Section = 'document__section',

    SubSection = 'document__sub-section',

    ResourceLink = 'document__resource-link',

    Question = 'document__question',

    QuestionAnswer = 'document__question-answer',

    QuestionHandedIn = 'document__question-handed-in',

    QuestionInitialAnswer = 'document__question-initial-answer',

    Media = 'document__content-media',

    MediaError = 'document__content-media--error',

    Table = 'document__content-table',

    StepList = 'document__step-list',

    StepListItem = 'document__step-list-item',

    Term = 'document__term',

    Rtti = 'document__rtti',
}
