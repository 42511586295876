import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReloadAssignmentService {
    private reloadSubject = new Subject<void>();

    triggerReload(): void {
        this.reloadSubject.next();
    }

    get reload$(): Observable<void> {
        return this.reloadSubject.asObservable();
    }
}
