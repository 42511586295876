import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {SearchResultInterface} from '@shared/interfaces/search.interface';
import {AbstractSearchDatasource} from '@core/search/contract/abstract-search-datasource.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';
import {SearchSelectors} from '@store/search/search.selectors';
import {AbstractSearchResultComponent} from '@core/search/contract/search-component.contract';
import {ViewContainerRefDirective} from '@component/view-container-ref/view-container-ref.directive';

export interface SearchResultsInterface<> {
    total: number;
    query: string;
    results: SearchResultInterface[];
    offset: number;
}

@Component({
    selector: 'app-search[dataSource]',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    @ViewChild(ViewContainerRefDirective, {static: true})
    private searchResultsContainer!: ViewContainerRefDirective

    private subscriptions: Subscription[] = [];

    @Input()
    public dataSource!: AbstractSearchDatasource;

    public searchResults?: SearchResultsInterface;

    public isMobile: boolean = false;

    public constructor(
        private store: Store,
        private componentResolver: ComponentFactoryResolver,
        private translateService: TranslateService,
        actionsSubject: ActionsSubject,
        breakpointObserver: BreakpointObserver,
    ) {
        this.isMobile = breakpointObserver.isMatched(Breakpoints.XSmall);
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(SearchSelectors.selectResultsByDataSourceId(this.dataSource.getDatasourceId())).subscribe(searchResults => this.handleLoadPage(searchResults)),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Note that ComponentFactoryResolver is deprecated in Angular13+, can be replaced with:
     * const component = this.viewContainerRef.createComponent(component);
     */
    private handleLoadPage(searchResults?: SearchResultsInterface): void {
        if (undefined === searchResults) {
            return;
        }

        const viewContainerRef: ViewContainerRef = this.searchResultsContainer.getViewContainerRef();

        viewContainerRef.clear(); // Clear previous results

        // Dynamically load components
        this.searchResults = searchResults;

        // Factorize result components
        searchResults.results.forEach(item => {
            const component: Type<AbstractSearchResultComponent> = this.dataSource.getComponentByItem(item);
            const componentFactory = this.componentResolver.resolveComponentFactory(component);
            const componentRef = viewContainerRef.createComponent(componentFactory);

            componentRef.instance.setData(item);
        });
    }
}
