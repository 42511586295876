import {CoverInterface} from '@shared/interfaces/cover.interface';
import {ProgressItemChapterDocumentInterface} from '@shared/interfaces/progress.interface';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {SourceInterface} from '@core/services/source/source.service';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {TableOfContentsInterface} from '@shared/interfaces/table-of-contents.interface';
import {SelfExamineUserInterface} from '@shared/interfaces/self-examine.interface';
import {EBookInterface} from '@shared/interfaces/ebook.interface';
import {StudyMaterialInterface} from '@shared/interfaces/study-material.interface';

export enum BookSourceEnum {
    Dps = 'dps',
    Alex = 'alex',
}

export interface BookInterface extends StudyMaterialInterface {
    color?: string;
    font?: string;
    lastVisited?: ProgressItemChapterDocumentInterface | null;
    subTitle?: string;
}

export interface BookDetailInterface extends BookInterface {
    author: string | null;
    introduction: string;
    eBooks: EBookInterface[];
    chapters: ChapterBookDetailsInterface[];
    tableOfContents: TableOfContentsInterface[];
    selfExamine: SelfExamineUserInterface | null;
    supportPhoneNumber: string | null;
    supportEmail: string | null;
    source: BookSourceEnum;
    organizationCustomerCode: string | null;
}

export interface BookDetailManualInterface extends BookDetailInterface {
    manual: SourceInterface;
}

export interface BookDetailGroupInterface extends BookDetailInterface {
    groups: GroupInterface[];
}

export interface BookListItemInterface extends BookInterface {
    cover: CoverInterface;
    ean: string | null;
}

export interface BookListInterface {
    books: BookListItemInterface[];
    total: number;
}
