import {EventEmitter, Injectable} from '@angular/core';
import {DocumentSourceInterface} from '../../modules/interfaces/source.interface';
import {DocumentSourceFactory} from '../../modules/factories/source.factory';
import {ApiDocumentDataInterface} from '../../interfaces/api_v1/document-data.interface';
import {ApiBookInterface} from '../../interfaces/api_v1/book.interface';

@Injectable()
export class DocumentService {
    protected bookId: number | null = null;

    protected bookTitle: string|null = null;

    protected bookCover: string|null = null;

    protected document!: ApiDocumentDataInterface;

    protected resources: Array<DocumentSourceInterface> = [];

    public documentChanged: EventEmitter<ApiDocumentDataInterface> = new EventEmitter();

    public bookChanged: EventEmitter<ApiBookInterface|null> = new EventEmitter();

    public constructor(
        private referenceFactory: DocumentSourceFactory,
    ) {
    }

    public setBook(book: ApiBookInterface|null = null): void {
        this.bookId = book ? book.id : null;
        this.bookTitle = book ? book.title : null;
        this.bookCover = book && book.cover ? book.cover : null;

        this.bookChanged.emit(book);
    }

    public setBookId(bookId: number): void {
        this.bookId = bookId;
    }

    public getBookId(): number|null {
        return this.bookId;
    }

    public getBookTitle(): string|null {
        return this.bookTitle;
    }

    public getBookCover(): string|null {
        return this.bookCover;
    }

    public getDocument(): ApiDocumentDataInterface {
        return this.document;
    }

    public setDocument(document: ApiDocumentDataInterface): void {
        this.document = document;

        if (this.document.sources.length > 0) {
            this.resources = this.referenceFactory.fromApiSources(this.document.sources);
        } else {
            this.resources = [];
        }

        this.documentChanged.emit(this.document);
    }

    public addResource(resource: DocumentSourceInterface): void {
        this.resources.push(resource);
    }

    public addOrReplaceResource(resource: DocumentSourceInterface): void {
        const existingResource: DocumentSourceInterface|undefined = this.resources.find(res => res.id === resource.id);

        if (existingResource !== undefined) {
            this.resources[this.resources.indexOf(existingResource)] = resource; // Replace at index
        } else {
            this.addResource(resource);
        }
    }

    public removeResourceById(id: string): void {
        const existingResource: DocumentSourceInterface|undefined = this.resources.find((resource) => resource.id === id);

        if (existingResource === undefined) {
            return;
        }

        this.resources.splice(this.resources.indexOf(existingResource), 1);
    }

    public getResources(): Array<DocumentSourceInterface> {
        return this.resources;
    }

    public hasAssignments(): boolean {
        return this.getDocument() && this.getDocument().assignments.length > 0;
    }

    public isBpvAssignment(): boolean {
        return this.hasAssignments() && this.getDocument().assignments[0].is_bpv;
    }
}
