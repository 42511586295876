import {Component, ElementRef, EventEmitter, HostBinding, OnDestroy, Output} from '@angular/core';
import {DocumentHostClassesEnum} from '@shared/modules/content/enums/document-host-classes.enum';
import {DocumentAnswerTypeEnum} from '@shared/modules/content/services/answers/answers.service';
import {DocumentAssignmentComponent} from '@shared/modules/content/modules/components/assignment/assignment.component';
import {AssignmentAnswersInterface, AssignmentInterface} from '@shared/interfaces/assignment.interface';
import {Subscription} from 'rxjs';

@Component({
    selector: 'assignment-answer-tabs',
    templateUrl: 'assignment-answer-tabs.component.html',
    styleUrls: ['assignment-answer-tabs.component.scss'],
})
export class DocumentAssignmentAnswerTabsComponent implements OnDestroy {
    readonly #elementRef: ElementRef;

    readonly #assignmentSubscription: Subscription;

    readonly documentAnswerTypes = DocumentAnswerTypeEnum;

    @HostBinding('class')
    readonly elementClass: string = DocumentHostClassesEnum.Question;

    @Output()
    readonly documentAnswerEvent: EventEmitter<DocumentAnswerTypeEnum> = new EventEmitter<DocumentAnswerTypeEnum>();

    answerTypeStatus: DocumentAnswerTypeEnum = DocumentAnswerTypeEnum.Current;

    readonly availableTabs: DocumentAnswerTypeEnum[] = [];

    constructor(elementRef: ElementRef, documentAssignmentComponent: DocumentAssignmentComponent) {
        this.#elementRef = elementRef;
        this.#assignmentSubscription = documentAssignmentComponent.subscribeToAssignment(assignment => this.#handleAssignment(assignment));
    }

    ngOnDestroy(): void {
        this.#assignmentSubscription.unsubscribe();
    }

    showAnswer(answerType: DocumentAnswerTypeEnum): void {
        this.answerTypeStatus = answerType;

        if(this.answerTypeStatus === DocumentAnswerTypeEnum.Correct) {
            this.#elementRef.nativeElement.closest('.document-list').classList.add('document-list--selected');
        } else {
            this.#elementRef.nativeElement.closest('.document-list').classList.remove('document-list--selected');
        }

        this.documentAnswerEvent.next(answerType);
    }

    #handleAssignment(assignment: AssignmentInterface): void {
        const answersModel: AssignmentAnswersInterface = assignment.answersV2;

        if (null !== answersModel.currentAnswers) {
            this.availableTabs.push(DocumentAnswerTypeEnum.Current);
        }

        if (null !== answersModel.initialAnswers) {
            this.availableTabs.push(DocumentAnswerTypeEnum.Initial);
        }

        if (null !== answersModel.reviewedAnswers) {
            this.availableTabs.push(DocumentAnswerTypeEnum.Reviewed);
        }

        if (null !== answersModel.correctAnswers) {
            this.availableTabs.push(DocumentAnswerTypeEnum.Correct);
        }
    }
}
