import {Injectable} from '@angular/core';
import {DocumentSourceInterface} from '../interfaces/source.interface';
import {DocumentReferenceComponent} from '../components/reference/reference.component';
import {IconEnum} from '../../enums/icon.enum';
import {DocumentSourceTypeEnum} from '../enums/source-type.enum';
import {SourceDisplayOnEnum} from '../enums/source-display-on.enum';
import {SourceAccessibleForEnum} from '../enums/source-accessible-for.enum';
import {MimetypeEnum} from '../enums/mimetype.enum';
import {LinkTargetEnum} from '../enums/link-target.enum';
import {ApiSourceInterface} from '../../interfaces/api_v1/source.interface';

@Injectable()
export class DocumentSourceFactory {
    public fromApiSource(sourceResponse: ApiSourceInterface): DocumentSourceInterface {
        const displayOn: any = sourceResponse.page_uuid
            ? SourceDisplayOnEnum.ThisPage
            : SourceDisplayOnEnum.AllPages;
        const accessibleFor: SourceAccessibleForEnum = sourceResponse.teacher_only === 1
            ? SourceAccessibleForEnum.Teachers
            : SourceAccessibleForEnum.TeachersAndStudents;

        return {
            id: sourceResponse.id.toString(),
            icon: sourceResponse.mimetype === MimetypeEnum.Text ? IconEnum.Link : IconEnum.Document,
            value: sourceResponse.title,
            target: LinkTargetEnum.Blank,
            editable: true,
            isOwner: sourceResponse.isOwner,
            uuid: displayOn ? sourceResponse.page_uuid : null,
            accessibleFor,
            displayOn,
            type: sourceResponse.type === 'file' ? DocumentSourceTypeEnum.File : DocumentSourceTypeEnum.Link,
            mimeType: sourceResponse.mimetype,
        };
    }

    public fromApiSources(references: Array<ApiSourceInterface>): Array<DocumentSourceInterface> {
        const documentReferences: Array<DocumentSourceInterface> = [];

        references.forEach((apiSource: ApiSourceInterface) => {
            documentReferences.push(this.fromApiSource(apiSource));
        });

        return documentReferences;
    }

    public fromDocumentReference(reference: DocumentReferenceComponent): DocumentSourceInterface {
        const linkElement: HTMLLinkElement = reference.documentReferenceLink.nativeElement;

        return {
            id: reference.id,
            icon: reference.iconBox.value,
            value: reference.value,
            link: linkElement.href,
            target: linkElement.target,
            editable: false,
            isOwner: false,
            uuid: null,
            mimeType: null,
        };
    }

    public fromBody(title: string, href: string, target: string): DocumentSourceInterface {
        return {
            id: null,
            icon: IconEnum.Link,
            value: title,
            link: href,
            target: target,
            editable: false,
            isOwner: false,
            uuid: null,
            mimeType: null,
        };
    }
}
