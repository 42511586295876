import {UserBaseInterface, UserInterface} from '@shared/interfaces/user.interface';

export enum AssignmentContentType {
    Normal = 'normal',
    Exam = 'exam',
}

export interface AttachmentMetadataInterface {
    id?: number;
    extension: string;
}

export interface AttachmentInterface {
    metadata: AttachmentMetadataInterface;
    type: string;
    value: string;
}

export interface AssignmentInterface<T = any> {
    dpsId: string;
    title: string;
    /** @deprecated use answersV2 instead, this field will be deleted in the future */
    answers: AssignmentAnswerInterface<T>[];
    fields: AssignmentFieldInterface[];
    hasAnswer: boolean;
    grade: number | null;
    reviewedOn: Date | null;
    session: AssignmentSessionInterface | null;
    answersV2: AssignmentAnswersInterface;
    attachments: AttachmentInterface[] | null;
    inputDisabled: boolean;
    autoCheck: boolean;
    selfExamine: boolean;
    feedback: AssignmentFeedbackInterface[] | null;
    contentType: AssignmentContentType;
    user: UserInterface;
}

/**
 * Fields are NULL when current user is not supposed to see its contents.
 */
export interface AssignmentAnswersInterface {
    currentAnswers: AssignmentAnswerInterface[] | null;
    correctAnswers: AssignmentAnswerInterface[] | null;
    initialAnswers: AssignmentAnswerInterface[] | null;
    reviewedAnswers: AssignmentAnswerInterface[] | null;
}

export interface AssignmentSessionInterface {
    handedIn: string;
    initialAnswers: AssignmentAnswerInterface[];
    reviewAnswers: AssignmentAnswerInterface[];
}

export interface AssignmentAnswerInterface<T = any> {
    assignmentDocumentDpsId: string;
    fieldId: number;
    value: T;
}

export interface AssignmentFieldInterface<T = any> {
    fieldId: number;
    options?: any;
}

export interface AssignmentReviewInterface {
    grade: number;
}

export interface AssignmentFeedbackInterface {
    id: number;
    fieldId: number;
    message: string;
    from: AssignmentFeedbackFromInterface,
    created: string,
    children: AssignmentFeedbackInterface[];
}

export interface AssignmentFeedbackFromInterface {
    user: UserBaseInterface,
    teacher: boolean;
}

export interface AssignmentPracticeExamInterface {
    handedInDate: string;
    score: number;
    questions: AssignmentPracticeExamQuestionInterface[],
}

export interface AssignmentPracticeExamQuestionInterface {
    title: string;
    score: string;
}
