import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrefixAssignmentTitlePipe} from './pipe/title.pipe';
import {DocumentAssignmentAnswerTabsComponent} from '@component/assignment/component/assignment-answer-tabs/assignment-answer-tabs.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        PrefixAssignmentTitlePipe,
        DocumentAssignmentAnswerTabsComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
    ],
    exports: [
        PrefixAssignmentTitlePipe,
        DocumentAssignmentAnswerTabsComponent,
    ],
})
export class AssignmentModule {
}
